function mobileMenuDropdown(options = {}) {
  // Convert string "true"/"false" to boolean if needed
  const closeOthers = options.closeOthers === "true" || options.closeOthers === true;
  // Get icon type (default to "arrow" if not specified or if set to "arrow")
  const iconType = options.icon === "plus" ? "plus" : "arrow";
  
  // Wait for DOM to be fully loaded
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
      // Add extra delay after DOM is loaded
      setTimeout(() => initDropdowns(closeOthers, iconType), 500);
    });
  } else {
    // DOM is already loaded, but still add the delay
    setTimeout(() => initDropdowns(closeOthers, iconType), 500);
  }
}

function initDropdowns(closeOthers, iconType) {
  // Get all folder link elements (including language picker)
  const folderLinks = document.querySelectorAll('.header-menu-nav-item a[data-folder-id], .language-picker-mobile a[data-folder-id]');
  
  // Loop through each folder link
  folderLinks.forEach(folderLink => {
    folderLink.classList.add('cse-dropdown-trigger');
    folderLink.setAttribute('tabindex', '0');
    
    // Set the icon href based on the iconType
    const iconElement = folderLink.querySelector('.header-dropdown-icon svg use');
    if (iconElement && iconType === "plus") {
      iconElement.setAttribute('href', '#plus');
    }
    
    // Extract the folder ID from the link
    const folderIdName = folderLink.getAttribute('data-folder-id');
    
    // Find the corresponding folder element
    // For regular menu items
    let folderElement = document.querySelector('[data-folder="' + folderIdName + '"]');
    
    // For language picker (it uses id instead of data-folder attribute)
    if (!folderElement && folderIdName === "language-picker") {
      folderElement = document.getElementById('multilingual-language-picker-mobile');
    }
    
    // Only proceed if we found a corresponding folder element
    if (folderElement) {
      folderElement.classList.add('cse-dropdown-content');
      
      // Insert the folder element after the folder link
      folderLink.after(folderElement);
      
      // Add click event listener to the folder link
      folderLink.addEventListener('click', function(event) {
        // Prevent the default behavior of the link
        event.preventDefault();
        
        // If closeOthers option is enabled, close all other dropdowns
        if (closeOthers) {
          folderLinks.forEach(link => {
            if (link !== folderLink) {
              link.classList.remove('open');
            }
          });
        }
        
        // Toggle the 'open' class on the folder link
        folderLink.classList.toggle('open');
        
        // Reset tabindex for all links
        setTimeout(() => {
          folderLinks.forEach(link => {
            link.setAttribute('tabindex', '0');
          });
        }, 300);
      });
    }
  });
}

// Make the plugin globally available
window.mobileMenuDropdown = mobileMenuDropdown;
